import React from "react"
import newCourseService from "../services/course_service";
import AuthenticatedFullscreenLayout from "../components/layouts/authenticatedFullscreenLayout";
import Step from "../components/course-browser/step";
const HaileyPage = () => (
    <AuthenticatedFullscreenLayout title="Hailey's Scene" section={newCourseService.sections.hailey}>
        <Step/>
    </AuthenticatedFullscreenLayout>
)

export default HaileyPage
